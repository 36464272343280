import { VisualizationTimePeriod } from './Visualizations.types';

export const VISUALIZATION_COLOR = {
	[VisualizationTimePeriod.Previous]: '#0093D0',
	[VisualizationTimePeriod.Current]: '#6FCF97'
};

export const VISUALIZATION_TIME_PERIOD_ORDER = [
	VisualizationTimePeriod.Previous,
	VisualizationTimePeriod.Current
];

export const VISUALIZATION_TIME_PERIOD_LABEL = {
	[VisualizationTimePeriod.Current]: 'dashboard.timePeriod.current',
	[VisualizationTimePeriod.Previous]: 'dashboard.timePeriod.previous'
};

export const LINEAR_AXIS_MAX_TICKS = 5;

export const CHART_CONFIG = {
	bar: {
		margin: {
			top: 10,
			right: 0,
			bottom: 40,
			left: 60
		},
		selectors: {
			axis: {
				y: 'y',
				x: 'x'
			},
			graph: 'graph',
			barGroup: 'group',
			bar: 'bar',
			barValue: 'value'
		}
	},
	line: {
		margin: {
			top: 10,
			right: 10,
			bottom: 40,
			left: 40
		},
		selectors: {
			axis: {
				y: 'y',
				x: 'x'
			},
			graph: 'graph',
			tooltip: 'tooltip'
		},
		dateFormat: 'YYYY-MM'
	},
	pie: {
		selectors: {
			graph: 'graph'
		}
	}
};