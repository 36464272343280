import { v4 as uuid } from 'uuid';
import { AxiosErrorResponse, Status, APIErrorCodes } from 'app-types';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { axiosClient } from 'Services/Api/client';
import DASHBOARD_API from 'Services/Api/Dashboard/Api';
import {
	DownloadExecutiveSummaryReportRequest,
	DownloadRiskScoreCardReportRequest
} from 'Services/Api/Dashboard/Types';
import { GenerateReportParams } from './types';
import axios, {
	AxiosRequestConfig,
	AxiosResponse,
	CancelTokenSource
} from 'axios';
import { omit } from 'lodash';
import { downloadLink } from 'Helpers/downloadLink';
import {
	DownloadReportRequest,
	DownloadReportRequestBase,
	DownloadReportResponse,
	ReportFileType,
	FileType,
	ReportType
} from 'Services/Api/Reports/Types';
import REPORTS_API from 'Services/Api/Reports/Api';
import { Notification } from 'Components';
import { APP_CONFIG } from 'app-config';
import { getAxiosErrorType, isAxiosErrorCodeEqual } from 'Helpers/api';

interface Document {
	params: DownloadReportRequestBase & FileType;
	cancelToken?: CancelTokenSource;
	result?: DownloadReportResponse;
	status: Status;
	statusEmailMeReport: Status;
}

interface ApiCreds{
	[key: string]:any
}
function getAPIcreds(){
	const currentAPIcred: ApiCreds = {};
	const urlhost = window.location.host;
	let APIurl = 'https://api-stage.venzagrc.com';
	if(!urlhost.includes('ev.') && !urlhost.includes('tg.')){
		APIurl = 'https://api-prod.venzagrc.com';
	}
	let i=1;
	let xx=0;
	let yy=0;
	currentAPIcred.userdata = {};
	currentAPIcred.newapitoken = {};
	currentAPIcred.apipath = {"pathcreds":{"path":localStorage.getItem("APIpath"),"pathadmin":localStorage.getItem("APIadmin")}};
	const APIuseradmin = localStorage.getItem("APIadmin");
	for(i = 0; i < localStorage.length; i++){
		if(localStorage.key(i)?.includes('.userDat')){
			currentAPIcred.userdata[xx] = localStorage.getItem(localStorage.key(i)!);
			xx++;
		}
	}
	for(i = 0; i < localStorage.length; i++){
		if(localStorage.key(i)?.includes('.accessToke')){
			currentAPIcred.newapitoken[yy] = localStorage.getItem(localStorage.key(i)!);
			yy++;
		}
	}
	const fullAPIurl = APIurl+'/?awsToken='+currentAPIcred.newapitoken[0]+APIuseradmin+'&page=module';
	return fullAPIurl;
}

class DownloadReportsStore {
	status = {
		emailMeReport: Status.Idle
	};

	byDocumentId: Record<string, Document> = {};

	constructor() {
		makeObservable(this, {
			status: observable,
			byDocumentId: observable
		});
	}

	@action
	addDocumentById(id: string, document: Document) {
		this.byDocumentId[id] = document;
	}

	@action
	async generateReport(
		params: GenerateReportParams,
		generateFn: (
			config: AxiosRequestConfig
		) => Promise<AxiosResponse<DownloadReportResponse>>
	) {
		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();
		let timeoutId: ReturnType<typeof setTimeout> | undefined = undefined;
		const { downloadId } = params;
		try {
			runInAction(() => {
				this.byDocumentId[params.downloadId] = {
					params,
					cancelToken: source,
					status: Status.Pending,
					statusEmailMeReport: Status.Idle
				};
			});

			// handle timeout after delay
			timeoutId = setTimeout(() => {
				const info = this.byDocumentId[params.downloadId];
				if (!info) return;
				info.cancelToken?.cancel();

				runInAction(() => {
					this.byDocumentId[params.downloadId] = {
						...info,
						status: Status.Failure
					};
				});
			}, APP_CONFIG.DOWNLOAD_MAX_MS);

			const result = await generateFn({
				cancelToken: source.token
			});

			// cancel timeout if resolved earlier
			timeoutId && clearTimeout(timeoutId);

			// result
			runInAction(() => {
				if (!this.byDocumentId[downloadId]) return;
				this.byDocumentId[downloadId] = {
					...this.byDocumentId[downloadId],
					status: Status.Success,
					result: result.data
				};
			});
		} catch (e) {
			if (!axios.isCancel(e)) {
				// hide popup if initial request to generate report has failed
				this.cancelGenerateReport({ downloadId });
				Notification.close(downloadId);
				throw e;
			}

			const axiosError = e as AxiosErrorResponse;
			const { isUnprocessableEntity } = getAxiosErrorType(axiosError);

			if (isUnprocessableEntity) {
				const isNoAvailablePropertiesError = isAxiosErrorCodeEqual(
					APIErrorCodes.NoAvailableProperties,
					axiosError
				);
				if (isNoAvailablePropertiesError) {
					throw e;
				}
				const isNoDataForReportGeneration = isAxiosErrorCodeEqual(
					APIErrorCodes.NoDataForReportGeneration,
					axiosError
				);
				if (isNoDataForReportGeneration) {
					throw e;
				}
			}
		}
	}

	@action
	async generateReportNEW(
		params: GenerateReportParams
	) {
		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();
		let timeoutId: ReturnType<typeof setTimeout> | undefined = undefined;
		const { downloadId } = params;
		try {
			runInAction(() => {
				this.byDocumentId[params.downloadId] = {
					params,
					cancelToken: source,
					status: Status.Pending,
					statusEmailMeReport: Status.Idle
				};
			});

			// handle timeout after delay
			timeoutId = setTimeout(() => {
				const info = this.byDocumentId[params.downloadId];
				if (!info) return;
				info.cancelToken?.cancel();

				runInAction(() => {
					this.byDocumentId[params.downloadId] = {
						...info,
						status: Status.Failure
					};
				});
			}, APP_CONFIG.DOWNLOAD_MAX_MS);

			const result = await axios({
				method: 'post',
				headers: { 
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				url: getAPIcreds(),
				data: {
					callfor: "report",
					calltype: "GeneralReport",
					downloadId: params.downloadId,
					callparams: params.reportdata
				},
			})
			.then(function (response) {
				if(!('response' in response.data)){
					return response;
				}else{
					response.data = JSON.parse('{"url":"#"}');
					return response;
				}
			})
			.catch(function (error) {
				window.console.log('API ERROR: '+error);
			});

			// cancel timeout if resolved earlier
			timeoutId && clearTimeout(timeoutId);

			// result
			runInAction(() => {
				if (!this.byDocumentId[downloadId]) return;
				this.byDocumentId[downloadId] = {
					...this.byDocumentId[downloadId],
					status: Status.Success,
					result: result?.data
				};
			});
		} catch (e) {
			if (!axios.isCancel(e)) {
				// hide popup if initial request to generate report has failed
				this.cancelGenerateReport({ downloadId });
				Notification.close(downloadId);
				throw e;
			}

			const axiosError = e as AxiosErrorResponse;
			const { isUnprocessableEntity } = getAxiosErrorType(axiosError);

			if (isUnprocessableEntity) {
				const isNoAvailablePropertiesError = isAxiosErrorCodeEqual(
					APIErrorCodes.NoAvailableProperties,
					axiosError
				);
				if (isNoAvailablePropertiesError) {
					throw e;
				}
				const isNoDataForReportGeneration = isAxiosErrorCodeEqual(
					APIErrorCodes.NoDataForReportGeneration,
					axiosError
				);
				if (isNoDataForReportGeneration) {
					throw e;
				}
			}
		}
	}

	@action
	async generateAndDownloadExecutiveSummaryReport(
		params: Omit<DownloadExecutiveSummaryReportRequest, 'pdfId'>
	) {
		const downloadId = uuid();
		/*
		const fileType = ReportFileType.Pdf;
		return await this.generateReportNEW(
			{
				downloadId,
				fileType,
				reportdata: params
			}
		);
		*/
		
		return await this.generateReport(
			{
				downloadId,
				fileType: ReportFileType.Pdf
			},
			async (config: AxiosRequestConfig) => {
				const request: DownloadExecutiveSummaryReportRequest = {
					...params,
					pdfId: downloadId
				};
				return await axiosClient.post<DownloadReportResponse>(
					DASHBOARD_API.GENERATE_REPORT,
					request,
					config
				);
			}
		);
		
	}

	@action
	async generateAndDownloadRiskScoreCard(
		params: Omit<DownloadRiskScoreCardReportRequest, 'pdfId'> = {}
	) {
		const downloadId = uuid();
		/*
		const fileType = ReportFileType.Pdf;
		return await this.generateReportNEW(
			{
				downloadId,
				fileType,
				reportdata: params
			}
		);
		*/
		
		return await this.generateReport(
			{
				downloadId,
				fileType: ReportFileType.Pdf
			},
			async (config: AxiosRequestConfig) => {
				const request: DownloadRiskScoreCardReportRequest = {
					...params,
					pdfId: downloadId
				};
				return await axiosClient.get(DASHBOARD_API.RISK_SCORE, {
					...config,
					params: request
				});
			}
		);
		
	}

	@action
	async generateAndDownloadReport(
		params: Omit<DownloadReportRequest, 'downloadId'>,
	) {
		const downloadId = uuid();

		const localreporttype = ['user_activity' ,'all_users','all_groups','all_roles','risk_score_card','executive_summary_pdf'];
		const checkreptype = localStorage.getItem("clickreport")!;
		window.console.log('***'+checkreptype+'***');
		if(localreporttype.includes(checkreptype)){
			return await this.generateReport(
				{
					downloadId,
					fileType: params.fileType
				},
				async (config) => {
					return await axiosClient.post<DownloadReportResponse>(
						REPORTS_API.DOWNLOAD_REPORT, // FOLLOW THIS TO REPORTS FOR OVERRIDE **** Just need to return generated file url with token after report is loaded to S3 bucket
						{
							...params,
							downloadId
						},
						config
					);
				}
			);
		}else{
			return await this.generateReportNEW(
				{
					downloadId,
					fileType: params.fileType,
					reportdata: params
				}
			);
		}	
	}

	@action
	cancelGenerateReport({ downloadId }: DownloadReportRequestBase) {
		const info = this.byDocumentId[downloadId];
		if (!info) return;
		info.cancelToken?.cancel();
		runInAction(() => {
			this.byDocumentId = omit(this.byDocumentId, downloadId);
		});
	}

	@action
	async emailMeReport(params: DownloadReportRequestBase) {
		const info = this.byDocumentId[params.downloadId];
		if (!info) return;
		runInAction(() => {
			this.byDocumentId[params.downloadId] = {
				...info,
				statusEmailMeReport: Status.Pending
			};
		});
		try {
			window.console.log('*** Download ID: ...'+params.downloadId);
			window.console.log('*** Params: ...');
			window.console.log(params);
			await axiosClient.post(DASHBOARD_API.GENERATE_REPORT_EMAIL_ME, {
				// TODO `pdfId` is temporary name, to be changed on BE
				pdfId: info.params.downloadId
			});
			this.cancelGenerateReport(params);
		} catch (e) {
			runInAction(() => {
				this.byDocumentId[params.downloadId] = {
					...info,
					statusEmailMeReport: Status.Failure
				};
			});
			throw e;
		}
	}

	@action
	async downloadReportByUUID(params: DownloadReportRequestBase) {
		const info = this.byDocumentId[params.downloadId];

		if (!info) return;
		if (info.result) downloadLink(info.result.url);
		this.cancelGenerateReport(params);
	}
}

export const downloadReportsStore = new DownloadReportsStore();
export default DownloadReportsStore;
