import { Col, DatePicker, Fx, Row, Select } from 'Components';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ReportType } from 'Services/Api/Reports/Types';
import {
	REPORT_DISPLAY_RESULTS_BY,
	REPORT_FIELD_LABEL,
	REPORT_PERIODS
} from '../EditReport.constants';
import styles from '../EditReport.module.scss';
import { ReportFormValues } from '../schema';
//import HistoricalRiskScore from './HistoricalRiskScore';

function Historical() {
	const { t } = useTranslation();
	const { initialValues, values } = useFormikContext<ReportFormValues>();
/*
	if (initialValues.reportType === ReportType.RiskScore) {
		return <HistoricalRiskScore />;
	}
*/
	return (
		<>
			<Row>
				<Col xs={10}>
					<Select.FormikField
						name="definedPeriod"
						label={t(REPORT_FIELD_LABEL.definedPeriod)}
						required
						fullWidth
						allowClear
						help={null}
						disabled={Boolean(values.fromToPeriod)}
					>
						{REPORT_PERIODS.map((opt) => (
							<Select.Option key={opt.value} value={opt.value}>
								{t(opt.label)}
							</Select.Option>
						))}
					</Select.FormikField>
				</Col>
				<Col xs={11}>
					<Fx self="bottom" align="bottom" fullHeight>
						<Fx shrink={0} className={styles.separator}>
							{t('report.separators.or')}
						</Fx>
						<DatePicker.RangePicker.FormikField
							name="fromToPeriod"
							fullWidth
							disabled={Boolean(values.definedPeriod)}
							getPopupContainer={(node) =>
								node.parentElement || document.body
							}
						/>
					</Fx>
				</Col>
			</Row>
			<Row>
				<Col xs={10}>
					<Select.FormikField
						name="displayResultsBy"
						label={t(REPORT_FIELD_LABEL.displayResultsBy)}
						required
						marginBottom={false}
						fullWidth
					>
						{REPORT_DISPLAY_RESULTS_BY.map((opt) => (
							<Select.Option key={opt.value} value={opt.value}>
								{t(opt.label)}
							</Select.Option>
						))}
					</Select.FormikField>
				</Col>
			</Row>
		</>
	);
}

export default Historical;
