import { ColumnProps } from 'antd/lib/table';
import {
	useCreateColumns,
	UseCreateColumnsInterface
} from 'Components/Table/createColumns';
import { OptionalValue } from 'Components';
import { useTranslation } from 'react-i18next';
import {
	PhishingGroupSummary,
	PhishingGroupSummaryFilterValues,
	PhishingGroupSummaryParams
} from 'Services/Api/Dashboard/Types';
import {
	renderMoodIndicatorColumn,
	renderTrendIndicator
} from '../../columns.helpers';
import { CAMPAIGN_SUMMARY_CATEGORY_LABEL_MAP } from '../CampaignsSummary/CampaignsSummary.constants';

export function useColumnProps<T extends PhishingGroupSummary>(
	helpers?: ReturnType<
		UseCreateColumnsInterface<T, PhishingGroupSummaryFilterValues>
	>
) {
	const { t } = useTranslation();
	const columns: Array<ColumnProps<T>> = [
		{
			...helpers?.sort,
			title: t('dashboard.groupSummary.name'),
			key: 'groupName',
			dataIndex: 'groupName',
			width: '19.82%',
			sortOrder: helpers?.getSortOrder('groupName')
		},
		{
			...helpers?.sort,
			title: t('dashboard.groupSummary.score'),
			key: 'score',
			dataIndex: 'score',
			width: '7.07%',
			sortOrder: helpers?.getSortOrder('score'),
			render: renderMoodIndicatorColumn
		},
		/*
		{
			...helpers?.sort,
			title: t('dashboard.groupSummary.variance'),
			key: 'variance',
			dataIndex: 'variance',
			render: (_, { variance }) => renderTrendIndicator(variance),
			width: '8.78%',
			sortOrder: helpers?.getSortOrder('variance')
		},
		*/
		{
			...helpers?.sort,
			title: t(CAMPAIGN_SUMMARY_CATEGORY_LABEL_MAP.sent),
			key: 'emailsSent',
			dataIndex: 'emailsSent',
			sortOrder: helpers?.getSortOrder('emailsSent'),
			render: (value) => <OptionalValue>{value}</OptionalValue>
		},
		{
			...helpers?.sort,
			title: t(CAMPAIGN_SUMMARY_CATEGORY_LABEL_MAP.emailsOpened),
			key: 'emailsOpen',
			dataIndex: 'emailsOpen',
			sortOrder: helpers?.getSortOrder('emailsOpen'),
			render: (value) => <OptionalValue>{value}</OptionalValue>
		},
		{
			...helpers?.sort,
			title: t(CAMPAIGN_SUMMARY_CATEGORY_LABEL_MAP.clicked),
			key: 'emailsClicked',
			dataIndex: 'emailsClicked',
			sortOrder: helpers?.getSortOrder('emailsClicked'),
			render: (value) => <OptionalValue>{value}</OptionalValue>
		},
		/*
		{
			...helpers?.sort,
			title: t(CAMPAIGN_SUMMARY_CATEGORY_LABEL_MAP.attachmentsOpened),
			key: 'attachmentsOpened',
			dataIndex: 'attachmentsOpened',
			sortOrder: helpers?.getSortOrder('attachmentsOpened'),
			render: (value) => <OptionalValue>{value}</OptionalValue>
		},
		*/
		{
			...helpers?.sort,
			title: t(CAMPAIGN_SUMMARY_CATEGORY_LABEL_MAP.submitted),
			key: 'dataSubmit',
			dataIndex: 'dataSubmit',
			sortOrder: helpers?.getSortOrder('dataSubmit'),
			render: (value) => <OptionalValue>{value}</OptionalValue>
		},
		{
			...helpers?.sort,
			title: t(CAMPAIGN_SUMMARY_CATEGORY_LABEL_MAP.success),
			key: 'success',
			dataIndex: 'success',
			width: '12.83%',
			sortOrder: helpers?.getSortOrder('success'),
			render: (value) => <OptionalValue>{value}</OptionalValue>
		}
	];

	return columns;
}

export const useColumns = ({
	params
}: {
	params: PhishingGroupSummaryParams;
}) => {
	const helpers = useCreateColumns<
		PhishingGroupSummary,
		PhishingGroupSummaryFilterValues
	>({
		filterValues: undefined,
		sortDesc: params.sort
	});

	return useColumnProps(helpers);
};
