import { FC } from 'react';
import { Col, Row, Fx } from 'Components';
import { isUndefined } from 'lodash';
import { TotalIndexComposite, Widget } from 'Pages/Dashboard/components';
import { useTranslation } from 'react-i18next';
import { useStores } from 'Hooks/useStore';

const OverviewWidgets: FC = () => {
	const { t } = useTranslation();
	const {
		dashboardStore: { myResults }
	} = useStores();

	const getWidgetData = (title: string, tooltip: string, percent?: number) => {
		if (isUndefined(percent)) {
			return null;
		}

		return <Widget value={percent} title={title} tooltip={tooltip}/>;
	};

	const WIDGET_DATA = [
		{
			title: t('dashboard.phishing'),
			tooltip: 'Successful completion (passing) percentage of simulated phishing attempts.',
			percent: myResults?.phishingScore
		},
		{
			title: t('dashboard.eLearning'),
			tooltip: 'Completion percentage of assigned learning paths.',
			percent: myResults?.elearningScore
		}/*,
		{
			title: t('dashboard.spotCheck'),
			percent: myResults?.spotCheckScore
		}*/
	];

	const getRiskScore = () => {
		if (!myResults) {
			return 0;
		}
		const { phishingScore, elearningScore, spotCheckScore } = myResults;

		return Math.round(
			(phishingScore + elearningScore + spotCheckScore) / 3
		);
	};

	return (
		<Row gutter={24} fullHeight>
			{WIDGET_DATA.map(({ title, tooltip, percent }) => {
				return (
					<Col span={6} key={title}>
						<Fx fullHeight>{getWidgetData(title, tooltip, percent)}</Fx>
					</Col>
				);
			})}
			<Col span={6}>
				<Fx fullHeight>
					<TotalIndexComposite
						riskScore={getRiskScore()}
						tooltip={t('dashboard.tabs.myResults.total.tooltip')}
					/>
				</Fx>
			</Col>
		</Row>
	);
};

export default OverviewWidgets;
