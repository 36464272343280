import { Modal, Tabs } from 'Components';
import DataTree from 'Contexts/DataTree/DataTree';
import {
	DataTreeContextProps,
	DataSyncRecord
} from 'Contexts/DataTree/DataTree.types';
import { Group } from 'Services/Api/Groups/Types';
import { useModal } from 'Hooks/useModal';
import { isEqual } from 'lodash';
import GroupTree, {
	GroupTreeProps
} from 'Pages/Groups/Group/modals/MoveGroupModal/GroupModal/GroupTree';
import { UsersTableFiltersValue } from 'Pages/Users/Users.types';
import { useState, useRef } from 'react';
import OwnershipGroups from './tabs/OwnershipGroups';
import { useStores } from 'Hooks/useStore';
import { observer } from 'mobx-react-lite';
import { SystemRoleType } from 'Services/Api/Roles/Types';
import { useTranslation } from 'react-i18next';
import { useGroupTree } from 'Hooks';

enum TabKey {
	Groups = 'Groups',
	OwnershipGroups = 'OwnershipGroups'
}

export type GroupsColumnFilterModalProps = Pick<
	GroupTreeProps,
	'withDeleted'
> & {
	initialValue: Pick<
		UsersTableFiltersValue,
		'groupIds' | 'ownershipGroupIds'
	>;
	setFilterValue: (filters: Partial<UsersTableFiltersValue>) => void;
};

function GroupsColumnFilterModal(props: GroupsColumnFilterModalProps) {
	const { t } = useTranslation();
	const { authStore } = useStores();
	const { hideModal } = useModal();
	const dataSyncRef = useRef<DataSyncRecord<Group>>({
		expandedKeys: [],
		data: {}
	});
	const { loadMore, loading } = useGroupTree({ withDeleted: false, isArchived: [false] });
	//const { loadMore, loading } = useGroupTree({ withDeleted: false, isArchived: [false], active: [true] });
	//const { loadMore, loading } = useGroupTree({ withDeleted: false }); // ORIGINAL
	const [value, setValue] = useState({
		initialValue: props.initialValue,
		value: props.initialValue
	});

	const handleGroupsChange: GroupTreeProps['onChange'] = (options) => {
		const newValue = options?.map((option) => String(option.key)) || [];
		setValue({
			...value,
			value: {
				...value.value,
				groupIds: newValue
			}
		});
	};
	const handleOwnershipGroupsChange: DataTreeContextProps['onChange'] = (
		options
	) => {
		const newValue = options?.map((option) => String(option.key)) || [];
		setValue({
			...value,
			value: {
				...value.value,
				ownershipGroupIds: newValue
			}
		});
	};

	const handleSubmit = async () => {
		// @NOTE: this functionality allows to check groups which are located to bellow under the not expanded key
		const notExpandedAndCheckedGroupIds = value.value?.groupIds?.filter(
			(key) => {
				if (!dataSyncRef.current.expandedKeys.includes(Number(key))) {
					const dataItem = dataSyncRef.current.data[key];
					return dataItem?.parentKey === null;
				}
				return false;
			}
		);
		let filteredValue = value.value;
		if (notExpandedAndCheckedGroupIds?.length) {
			const result = await Promise.all(
				notExpandedAndCheckedGroupIds.map((key) =>
					loadMore(dataSyncRef.current.data[key]?.key as number)
				)
			);
			const groupIds = result.flat().reduce<string[]>((acc, { id }) => {
				const idToString = String(id);
				if (!notExpandedAndCheckedGroupIds.includes(idToString)) {
					acc.push(idToString);
				}
				return acc;
			}, []);

			filteredValue = {
				...filteredValue,
				groupIds: (filteredValue.groupIds ?? []).concat(groupIds)
			};
		}
		props.setFilterValue(filteredValue);
		hideModal();
	};

	const isDirty = !isEqual(value.initialValue, value.value);

	return (
		<Modal
			visible
			size="sm"
			okText={t('apply')}
			onOk={handleSubmit}
			onCancel={hideModal}
			okButtonProps={{
				disabled: !isDirty,
				loading: loading
			}}
		>
			<Tabs>
				<Tabs.TabPane key={TabKey.Groups} tab={t('groups.allGroups')}>
					<GroupTree
						withDeleted={props.withDeleted}
						initialValue={value.initialValue.groupIds?.map(
							(id) => ({ key: id })
						)}
						onChange={handleGroupsChange}
						dataSyncRef={dataSyncRef}
						isMultiSelection
					>
						<DataTree.List highlightValue checkable checkStrictly />
					</GroupTree>
				</Tabs.TabPane>
				{authStore.currentUser?.primarySystemRole.role ===
					SystemRoleType.SuperAdmin && (
					<Tabs.TabPane
						key={TabKey.OwnershipGroups}
						tab={t('ownershipGroups.title')}
					>
						<OwnershipGroups
							initialValue={value.initialValue.ownershipGroupIds?.map(
								(id) => ({ key: id })
							)}
							onChange={handleOwnershipGroupsChange}
						/>
					</Tabs.TabPane>
				)}
			</Tabs>
		</Modal>
	);
}

export default observer(GroupsColumnFilterModal);
