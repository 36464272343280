import { USER_ACTIVE_LABEL } from 'app-config';
import {
	ReportDisplayResultsBy,
	ReportFileType,
	ReportPeriod,
	ReportRecurringFrequency,
	ReportUserStatus,
	ReportType
} from 'Services/Api/Reports/Types';
import { ReportFormValues } from './schema';

type ReportFieldsLabeled = Pick<
	ReportFormValues,
	| 'name'
	| 'fileType'
	| 'definedPeriod'
	| 'displayResultsBy'
	| 'groupId'
	| 'showOnlyProperties'
	| 'isRecurring'
	| 'scheduleFrequency'
	| 'emailMeReport'
	| 'emailsList'
	| 'userStatus'
	| 'userLoginStatus'
	| 'executeSummaryTypes'
>;
export const REPORT_FIELD_LABEL: Record<keyof ReportFieldsLabeled, string> = {
	name: 'report.fields.name',
	fileType: 'report.fields.fileType',
	definedPeriod: 'report.fields.period',
	displayResultsBy: 'report.fields.displayResultsBy',
	groupId: 'report.fields.groups',
	showOnlyProperties: 'dashboard.filters.properties.label',
	isRecurring: 'report.fields.recurring',
	scheduleFrequency: 'report.fields.scheduleFrequency',
	emailMeReport: 'report.fields.emailMeReport',
	emailsList: 'report.fields.emailsList',
	userStatus: 'user.status',
	userLoginStatus: 'user.loginStatus',
	executeSummaryTypes: 'report.fields.tabs'
};

export const REPORT_FILE_TYPES = [
	{
		label: 'appConfig.exportFormats.csv',
		value: ReportFileType.Csv
	},
	{
		label: 'appConfig.exportFormats.xlsx',
		value: ReportFileType.Xlsx
	}
];

export const REPORT_FILE_TYPES_ALL = [
	...REPORT_FILE_TYPES,
	{
		label: 'appConfig.exportFormats.pdf',
		value: ReportFileType.Pdf
	}
];

export const REPORT_PERIODS = [
	{
		label: 'report.periods.threeMonths',
		value: ReportPeriod.ThreeMonths
	},
	{
		label: 'report.periods.sixMonths',
		value: ReportPeriod.SixMonths
	},
	{
		label: 'report.periods.year',
		value: ReportPeriod.Year
	},
	{
		label: 'report.periods.twoYears',
		value: ReportPeriod.TwoYears
	}
];

export const REPORT_DISPLAY_RESULTS_BY_MAP = {
	[ReportDisplayResultsBy.Month]: {
		label: 'report.displayResultsBy.month',
		value: ReportDisplayResultsBy.Month
	},
	[ReportDisplayResultsBy.Quarters]: {
		label: 'report.displayResultsBy.quarter',
		value: ReportDisplayResultsBy.Quarters
	},
	[ReportDisplayResultsBy.Year]: {
		label: 'report.displayResultsBy.year',
		value: ReportDisplayResultsBy.Year
	}
};
export const REPORT_DISPLAY_RESULTS_BY = Object.values(
	REPORT_DISPLAY_RESULTS_BY_MAP
);

export const REPORT_FREQUENCIES_MAP = {
	[ReportRecurringFrequency.Daily]: {
		label: 'report.scheduleFrequency.daily',
		value: ReportRecurringFrequency.Daily
	},
	[ReportRecurringFrequency.Weekly]: {
		label: 'report.scheduleFrequency.weekly',
		value: ReportRecurringFrequency.Weekly
	},
	[ReportRecurringFrequency.Monthly]: {
		label: 'report.scheduleFrequency.monthly',
		value: ReportRecurringFrequency.Monthly
	},
	[ReportRecurringFrequency.Quarterly]: {
		label: 'report.scheduleFrequency.quarterly',
		value: ReportRecurringFrequency.Quarterly
	},
	[ReportRecurringFrequency.Annually]: {
		label: 'report.scheduleFrequency.annually',
		value: ReportRecurringFrequency.Annually
	}
};

export const REPORT_FREQUENCIES = Object.values(REPORT_FREQUENCIES_MAP);

export const REPORT_USER_STATUS = [
	{
		label: USER_ACTIVE_LABEL.YES,
		value: ReportUserStatus.Active
	},
	{
		label: USER_ACTIVE_LABEL.NO,
		value: ReportUserStatus.Inactive
	}
];

export const REPORT_TIME_PERIOD_HIDDEN: ReportType[] = [
	ReportType.UserActivity,
	ReportType.Users,
	ReportType.Groups,
	ReportType.Roles,
	//ReportType.ExecutiveSummary,
	ReportType.ActiveCampaigns
];

export const REPORT_TYPES_PDF_ONLY = [
	//ReportType.ExecutiveSummary,
	//ReportType.RiskScore
];
