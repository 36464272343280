import * as d3 from 'd3';
import {
	LINEAR_AXIS_MAX_TICKS,
	VISUALIZATION_COLOR
} from './Visualizations.constants';
import { VisualizationTimePeriod } from './Visualizations.types';

const colorScale = (domain: string[]) =>
	d3
		.scaleOrdinal(
			domain,
			domain.map((d) => VISUALIZATION_COLOR[d as VisualizationTimePeriod])
		)
		.unknown('#7c8180');

const linearScale = (min: number, max: number, h: number, w: number) => {
	const intervals = [100, 250, 500, 1000, 1500, 2000, 2500, 5000, 10000, 25000, 50000];
	const ticksInterval = Math.round(max / LINEAR_AXIS_MAX_TICKS);
	const interval = intervals.reduce(function (prev, curr) {
		return Math.abs(curr - ticksInterval) < Math.abs(prev - ticksInterval)
			? curr
			: prev;
	});

	const scaleMaxValue = max === 0 ? 1 : max;
	const scale = d3
		.scaleLinear()
		.range([h, 0])
		.domain([min, Math.ceil(scaleMaxValue / interval) * interval]);
	const scaleAxis = d3
		.axisLeft(scale)
		.ticks(0)
		.tickSizeOuter(0) // remove end ticks
		.tickSize(-w)
		.tickFormat(d3.format('d'))
		.tickPadding(8);

	if (max !== 0) {
		scaleAxis.ticks(
			max > LINEAR_AXIS_MAX_TICKS
				? LINEAR_AXIS_MAX_TICKS // max
				: 1 // show only start/end ticks
		);
	}

	return {
		scale,
		scaleAxis
	};
};

export { colorScale, linearScale };
