import cn from 'classnames';
import { Fx, OptionalValue } from 'Components';
import { ProgressStatus } from 'Pages/Dashboard/Dashboard.types';
import { FC } from 'react';
import styles from './Statistic.module.scss';

interface StatisticPropsNULL {
	value?: number | string | null;
	vertical?: boolean;
	className?: string;
	inheritColor?: boolean;
	size?: 'md' | 'lg';
	type?: 'default' | 'primary' | ProgressStatus | null;
}
const StatisticNULL: FC<StatisticPropsNULL> = ({
	value,
	vertical,
	inheritColor,
	className,
	children,
	size = 'md',
	type = 'primary'
}) => {
	return (
		<Fx
			shrink={0}
			align="middle"
			className={cn(
				styles.root,
				styles[size],
				styles[type || ''],
				className,
				{
					[styles.vertical]: vertical,
					[styles.inheritColor]: inheritColor
				}
			)}
		>
			<div className={styles.textHolder}>
				<h1 className={styles.count}>
					<OptionalValue>{value}</OptionalValue>
				</h1>
				{children}
			</div>
		</Fx>
	);
};

export default StatisticNULL;
