import { DownloadUrl, Entity } from 'app-types';
import {
	DashboardTabKey,
	ElearningGroupSummary,
	OverviewGroupSummary,
	PhishingActiveCampaign,
	PhishingGroupSummary,
	SpotCheckGroupsOverviewItem
} from '../Dashboard/Types';
import { Group } from '../Groups/Types';
import { SystemRole } from '../Roles/Types';
import { User, UserLoginStatus, PreviewUser } from '../Users/Types';

export enum ReportType {
	PhishingGroupSummary = 'phishing_group_summary',
	OverviewGroupSummary = 'overview_group_summary',
	ElearningGroupSummary = 'e_learning_group_summary',
	SpotcheckGroupSummary = 'spot_check_group_summary',
	ActiveCampaigns = 'active_campaigns_group_summary',
	UserActivity = 'user_activity',
	Users = 'all_users',
	Groups = 'all_groups',
	Roles = 'all_roles'/*,
	RiskScore = 'risk_score_card',
	ExecutiveSummary = 'executive_summary_pdf'
	*/
}

export enum ReportTimePeriod {
	Current = 1,
	Historical = 0
}

export enum ReportPeriod {
	ThreeMonths = 1,
	SixMonths = 2,
	Year = 3,
	TwoYears = 4
}

export enum ReportDisplayResultsBy {
	Month = 1,
	Quarters = 2,
	Year = 3
}

export enum ReportRecurringFrequency {
	Daily = 1,
	Weekly = 2,
	Monthly = 3,
	Quarterly = 4,
	Annually = 5
}

export enum ReportUserStatus {
	Active = 'active',
	Inactive = 'inactive'
}

export enum ReportFileType {
	Csv = 'csv',
	Xlsx = 'xlsx',
	Pdf = 'pdf'
}

interface ReportScheduleFrequency {
	type: ReportRecurringFrequency;
	frequency?: number | string; // weekday/date iso
	time: string;
}

export type Report = {
	reportType: ReportType;
	name: string;
	fileType: ReportFileType;

	timePeriodType: ReportTimePeriod;
	definedPeriod?: ReportPeriod;
	fromToPeriod?: {
		from: string;
		to: string;
	};
	displayResultsBy?: ReportDisplayResultsBy;

	showOnlyProperties: boolean;
	groupId?: number;
	ownershipGroupId?: number;

	isRecurring: boolean;

	scheduleFrequency?: ReportScheduleFrequency;

	emailMeReport?: boolean;
	emailsList?: string[];
};

export interface UserActivityParams {
	pageSize: number;
	pageNumber: number;
	userActivityTab: UserActivityTab;
}

type ReportVariation<T extends keyof ReportTypeData> = Omit<
	Report,
	'reportType'
> & {
	reportType: T;
} & ReportTypeData[T];

type ReportTypeData = {
	[ReportType.Users]: {
		userStatus?: ReportUserStatus[];
		userLoginStatus?: UserLoginStatus[];
	};
	/*
	[ReportType.ExecutiveSummary]: {
		executeSummaryTypes: DashboardTabKey[];
	};
	*/
	[ReportType.UserActivity]: UserActivityParams;
};

type ReportUsers = ReportVariation<ReportType.Users>;
//export type ReportExecutiveSummary = ReportVariation<ReportType.ExecutiveSummary>;

//type ReportVariations = ReportUsers | ReportExecutiveSummary;
type ReportVariations = ReportUsers;

type ReportGeneric = Omit<Report, 'reportType'> & {
	reportType: Exclude<Report['reportType'], ReportVariations['reportType']>;
};

export type Reports = Report &
	Omit<ReportUsers, 'reportType'>;/* &
	Omit<ReportExecutiveSummary, 'reportType'>;*/

export type AddReportRequest = ReportVariations | ReportGeneric;

export type EditReportRequest = Partial<AddReportRequest> &
	Pick<ReportDetails, 'id'>;

export interface StandardReportListItem {
	id: number;
	//category: 'summary' | 'data_export' | 'executive_reports';
	category: 'summary' | 'data_export';
	type: ReportType;
}

// Saved Report

export type SavedReport = Pick<Report, 'name' | 'emailsList'> & {
	reportType: ReportType;
	id: number;
	recurrenceType?: ReportRecurringFrequency;
	fileFormat: ReportFileType;
};

export type GetSavedReportsRequest = Partial<{
	reportName: string;
	reportTypes: ReportType[];
	recurrenceTypes: ReportRecurringFrequency[];
	emailsList: string;
}> & {
	sort: string;
};

export type ReportRequestBase = {
	reportId: number;
};

export type ReportDetails = Omit<
	ReportVariations | ReportGeneric,
	'groupId'
> & {
	id: number;
	group?: Entity;
	ownershipGroup?: Entity;
};

// Preview Report

type PreviewReportRequestBase = Pick<
	Report,
	| 'name'
	| 'timePeriodType'
	| 'definedPeriod'
	| 'displayResultsBy'
	| 'showOnlyProperties'
	| 'groupId'
	| 'ownershipGroupId'
	| 'reportType'
> & {
	fromPeriodFrom?: string;
	fromPeriodTo?: string;
};

export type PreviewReportRequest =
	| ReportRequestBase
	| (PreviewReportRequestBase & ReportTypeData[keyof ReportTypeData]);

export type PreviewReportResponse =
	| PreviewReportTypeData<ReportType.SpotcheckGroupSummary>
	| PreviewReportTypeData<ReportType.PhishingGroupSummary>
	| PreviewReportTypeData<ReportType.ActiveCampaigns>
	| PreviewReportTypeData<ReportType.ElearningGroupSummary>
	| PreviewReportTypeData<ReportType.OverviewGroupSummary>
	| PreviewReportTypeData<ReportType.Roles>
	| PreviewReportTypeData<ReportType.Groups>
	| PreviewReportTypeData<ReportType.Users>
	| PreviewReportTypeData<ReportType.UserActivity>;

export type ReportDataByReportTypeUserActivity = {
	[key: string]: number | User[];
	totalCount: number;
};

export type ReportDataByAllUsersReportType = {
	data: PreviewUser[];
	totalCount: number;
};

export type ReportDataByReportType = {
	[ReportType.SpotcheckGroupSummary]: SpotCheckGroupsOverviewItem & {
		overallScore?: number | null;
		participationScore?: number | null;
		responseScore?: number | null;
	};
	[ReportType.PhishingGroupSummary]: PhishingGroupSummary;
	[ReportType.ElearningGroupSummary]: ElearningGroupSummary;
	[ReportType.ActiveCampaigns]: PhishingActiveCampaign;
	[ReportType.OverviewGroupSummary]: OverviewGroupSummary;
	[ReportType.Roles]: SystemRole;
	[ReportType.Groups]: Group;
	[ReportType.Users]: User;
	[ReportType.UserActivity]: ReportDataByReportTypeUserActivity;
};

export type PreviewReportTypeData<K = ReportType> = {
	reportType: K;
	name: string;
	period: {
		from: string;
		to?: string;
	};
	displayResultsBy?: ReportDisplayResultsBy;
	data: ReportType extends ReportType.UserActivity
		? ReportDataByReportTypeUserActivity
		: ReportType extends ReportType.Users
		? ReportDataByAllUsersReportType
		: (PreviewReportTypeListItem<K> & PreviewReportTypeListItemBase)[];
};

export type PreviewReportTypeListItemBase = { groupperiod?: string };

export type PreviewReportTypeListItem<
	K
> = K extends keyof ReportDataByReportType
	? ReportDataByReportType[K]
	: unknown;

// Get report by token
export interface GetReportByTokenRequest {
	token: string;
}

export enum ReportByTokenType {
	ExecutiveSummary = 'Executive summary',
	ReminderAboutUsersAndGroupsDeletion = 'Reminder about users and groups deletion',
	WeeklySummary = 'Weekly summary',
	UserSavedReports = 'User saved reports',
	RiskScore = 'Risk Score',
	StandardReports = 'STANDARD_REPORTS',
	UsersListParams = 'USERS_LIST_WITH_PARAMS',
	UsersActivityDashboard = 'USERS_ACTIVITY_DASHBOARD',
	SystemRoles = 'SYSTEM_ROLES',
	OwnershipGroups = 'OWNER_SHIP_GROUPS',
	DeletedGroups = 'DELETED_GROUPS',
	Groups = 'GROUPS',
	AuditLog = 'AUDIT_LOG'
}

export type ReportByTokenDetails = {
	name: string;
	expirationDate: string;
} & (
	| RegularReportByTokenDetails
	| {
			type:
				| ReportByTokenType.ExecutiveSummary
				| ReportByTokenType.RiskScore
				| ReportByTokenType.StandardReports
				| ReportByTokenType.UsersListParams
				| ReportByTokenType.UsersActivityDashboard
				| ReportByTokenType.SystemRoles
				| ReportByTokenType.OwnershipGroups
				| ReportByTokenType.DeletedGroups
				| ReportByTokenType.Groups
				| ReportByTokenType.AuditLog;
			meta: DownloadPdfReportRequest;
	  }
	| { type: ReportByTokenType.UserSavedReports; meta: ReportRequestBase }
);

export type RegularReportByTokenDetails = {
	name: string;
	expirationDate: string;
	type:
		| ReportByTokenType.ReminderAboutUsersAndGroupsDeletion
		| ReportByTokenType.WeeklySummary;
	meta: DownloadRegularReportRequest['input'];
};

export type DownloadRegularReportRequest = {
	input: {
		// @NOTE: peakV3Id relates to user who requests report url and needs for validation report user === user who requests report
		reportOwnerPeakV3Id: string;
		date: string;
	};
};

export type DownloadRegularReportResponse<
	T = ReportByTokenType
> = T extends ReportByTokenType.ReminderAboutUsersAndGroupsDeletion
	? {
			exportUsersAndGroupsForDeletion: DownloadUrl;
	  }
	: T extends ReportByTokenType.WeeklySummary
	? {
			exportWeeklyReport: DownloadUrl;
	  }
	: null;

export type FileType = Pick<Report, 'fileType'>;

export type DownloadSavedReportRequest = ReportRequestBase;

export type DownloadSavedReportRequestWithFileType = ReportRequestBase &
	FileType;
export type DownloadDynamicReportRequest = PreviewReportRequest & FileType;

export type DownloadPdfReportRequest = {
	pdfId: string;
};

export type DownloadReportRequestBase = {
	downloadId: string;
};
export type DownloadReportRequest = (
	| DownloadDynamicReportRequest
	| DownloadSavedReportRequestWithFileType
) &
	DownloadReportRequestBase;

export type DownloadReportResponse = {
	url: string;
};

export type CloneReportRequest = Pick<SavedReport, 'id' | 'name'>;

export enum UserActivityTab {
	Total = 'total',
	Logged = 'logged',
	LockedOut = 'lockedOut',
	Created = 'created',
	Deactivated = 'deactivated',
	Deleted = 'deleted'
}
