import { isArray, keyBy, max, sumBy } from 'lodash';
import moment from 'moment';
import { VisualizationTimePeriod } from '../Visualizations.types';
import linechart from './chart';
import {
	LineChartProps,
	LineTimeInterval,
	LineValue,
	LineValueCategories,
	LineValueItem,
	LineValuesByPeriod
} from './LineChart.types';

const CATEGORY_BY_TIME_PERIOD: Record<
	VisualizationTimePeriod,
	keyof LineValueCategories
> = {
	[VisualizationTimePeriod.Current]: 'y',
	[VisualizationTimePeriod.Previous]: 'y0'
};

export const getTimeRanges = (props: LineChartProps) => {
	const currentEndDate = moment().endOf('M');
	const currentStartDate = moment(currentEndDate)
		.subtract(11, 'M')
		.startOf('M');

	const previousEndDate = moment(currentStartDate)
		.subtract(1, 'M')
		.endOf('M');
	const previousStartDate = moment(previousEndDate)
		.subtract(11, 'M')
		.startOf('M');

	const xRange = {
		start: currentStartDate,
		end: currentEndDate
	};

	const curr =
		!props.categories ||
		props.categories.includes(
			CATEGORY_BY_TIME_PERIOD[VisualizationTimePeriod.Current]
		)
			? {
					...xRange,
					timePeriod: VisualizationTimePeriod.Current,
					series: props.categories
						? CATEGORY_BY_TIME_PERIOD[
								VisualizationTimePeriod.Current
						  ]
						: undefined
			  }
			: undefined;

	const prev =
		!props.categories ||
		props.categories.includes(
			CATEGORY_BY_TIME_PERIOD[VisualizationTimePeriod.Previous]
		)
			? {
					...(!props.categories
						? {
								start: previousStartDate,
								end: previousEndDate
						  }
						: xRange),
					timePeriod: VisualizationTimePeriod.Previous,
					series: props.categories
						? CATEGORY_BY_TIME_PERIOD[
								VisualizationTimePeriod.Previous
						  ]
						: undefined
			  }
			: undefined;

	return [...(prev ? [prev] : []), ...(curr ? [curr] : [])];
};

export const prepareData = (
	data: LineValue[],
	chart: ReturnType<typeof linechart>
) => {
	const byDate = keyBy(data, (d) => d.x);

	const newData = chart.ranges.reduce<LineValuesByPeriod[]>(
		(acc, timeRange) => {
			const { start, end, timePeriod, series = 'y' } = timeRange;
			const year = chart.toDate(end).year();
			const dates: LineValueItem[] = [];

			while (dates[dates.length - 1]?.x !== chart.toDateFormatted(end)) {
				const lastDate = dates[dates.length - 1];
				const nextDate = lastDate
					? chart.toDate(lastDate.x).add(1, 'M')
					: start;

				const nextDateFormatted = chart.toDateFormatted(nextDate);
				const dateValue: LineValueItem = {
					x: nextDateFormatted,
					y:
						byDate[nextDateFormatted]?.[
							series as keyof Pick<LineValue, 'y' | 'y0'>
						] ?? 0,
					axisYear:
						chart.toDate(timeRange.start).year() === nextDate.year()
							? chart.toDate(chart.xRange.start).year()
							: chart.toDate(chart.xRange.end).year()
				};
				dates.push(dateValue);
			}

			acc.push({
				year,
				timePeriod,
				values: dates
			});

			return acc;
		},
		[]
	);

	return newData;
};

export const getChartData = (
	data: LineValuesByPeriod[],
	timeInterval: LineTimeInterval,
	timeRangeEndYears: number[] | undefined,
	chart: ReturnType<typeof linechart>
) => {
	const newData = data.reduce<LineValuesByPeriod[]>((acc, period) => {
		if (
			isArray(timeRangeEndYears) &&
			!timeRangeEndYears.includes(period.year)
		) {
			return acc;
		}
		if (timeInterval === LineTimeInterval.Month) {
			acc.push(period);
			return acc;
		}
		const byTimePeriodDate = period.values.reduce<
			Record<string, LineValueItem[]>
		>((acc, value) => {
			const date = chart.toDateFormatted(
				chart.toDate(value.x).startOf('quarter')
			);
			if (!acc[date]) {
				acc[date] = [];
			}
			acc[date].push(value);
			return acc;
		}, {});
		acc.push({
			...period,
			values: Object.keys(byTimePeriodDate).map((date) => {
				const y = sumBy(byTimePeriodDate[date], (opt) => opt.y);

				return {
					x: date,
					y,
					axisYear: byTimePeriodDate[date][0].axisYear
				};
			})
		});
		return acc;
	}, []);
	return newData;
};

let useacc = 0;
export const getYMaxValue = (data: LineValuesByPeriod[]) => {
	return data.reduce((acc, d) => {
		for(let i=0;i<d.values.length;i++){
			if(Number(d.values[i].y)){
				if(Number(d.values[i].y) > useacc){
					useacc = Number(d.values[i].y);
				}
			}
		}
		/*
		const maxValue = max(d.values.map((v) => v.y)) ?? 0;
		if (maxValue > acc) acc = maxValue;
		return acc;
		*/
		return useacc;
	}, 0);
};
