import { APP_CONFIG } from 'app-config';
import { TFunction } from 'i18next';
import { isString } from 'lodash';
import moment from 'moment';
import { STANDARD_REPORT_TYPE_MAP } from 'Pages/Reports/Standard/Standard.constants';
import {
	Report,
	//ReportExecutiveSummary,
	AddReportRequest,
	ReportType,
	ReportFileType,
	ReportTimePeriod,
	ReportDetails
} from 'Services/Api/Reports/Types';
import { REPORT_TYPES_PDF_ONLY } from './EditReport.constants';
import { ReportFormData, ReportFormValues } from './schema';

export const getNewInitialValues = (
	type: ReportType,
	t: TFunction,
	data: ReportFormData = {}
): ReportFormValues => {
	const values: ReportFormValues = {
		data,
		name: STANDARD_REPORT_TYPE_MAP[type].label(t),
		reportType: type,
		/*
		fileType: REPORT_TYPES_PDF_ONLY.includes(type)
			? ReportFileType.Pdf
			: ReportFileType.Csv,
		*/
		fileType: ReportFileType.Csv,
		timePeriodType: ReportTimePeriod.Current,
		showOnlyProperties: false,
		isRecurring: false
	};
/*
	if (type === ReportType.ExecutiveSummary) {
		const executeSummaryTypes = (data.tabs ?? []).reduce<
			Required<ReportFormValues>['executeSummaryTypes']
		>((acc, tab) => {
			if (tab.disabled) return acc;
			acc.push(tab.value);
			return acc;
		}, []);
		return {
			...values,
			executeSummaryTypes
		};
	}
*/
	return values;
};
const isEdit = (
	report: Parameters<typeof getInitialValues>[0]
): report is ReportDetails => Boolean((report as ReportDetails).id);

export function getInitialValues(
	report: ReportDetails | Pick<ReportDetails, 'reportType'>,
	t: TFunction,
	data: ReportFormData = {}
): ReportFormValues {
	if (isEdit(report)) {
		const {
			group,
			ownershipGroup,
			fromToPeriod,
			scheduleFrequency,
			...reportValues
		} = report;
		return {
			data,
			...reportValues,
			ownershipGroupId: converter.ownershipGroupId.to(report),
			groupId: converter.groupId.to(report),
			fromToPeriod: converter.fromToPeriod.to(report),
			scheduleFrequency: converter.scheduleFrequency.to(report)
		};
	}

	return getNewInitialValues(report.reportType, t, data);
}

export const prepareRequest = (values: ReportFormValues): AddReportRequest => {
	let request: Report = {
		reportType: values.reportType,
		name: values.name,
		fileType: values.fileType,
		timePeriodType: values.timePeriodType,
		showOnlyProperties: values.showOnlyProperties,
		groupId: converter.groupId.from(values),
		ownershipGroupId: converter.ownershipGroupId.from(values),
		isRecurring: values.isRecurring,
		scheduleFrequency: converter.scheduleFrequency.from(values),
		emailMeReport: values.isRecurring
			? values.emailMeReport ?? false
			: undefined,
		emailsList: values.emailsList
	};

	if (values.timePeriodType === ReportTimePeriod.Historical) {
		request = {
			...request,
			definedPeriod: values.definedPeriod,
			displayResultsBy: values.displayResultsBy,
			fromToPeriod: converter.fromToPeriod.from(values)
		};
	}

	switch (request.reportType) {
		case ReportType.Users:
			return {
				...request,
				reportType: request.reportType,
				userStatus: values.userStatus?.length
					? values.userStatus
					: undefined,
				userLoginStatus: values.userLoginStatus?.length
					? values.userLoginStatus
					: undefined
			};
		/*
		case ReportType.ExecutiveSummary:
			return {
				...request,
				reportType: request.reportType,
				executeSummaryTypes: values.executeSummaryTypes?.length
					? (values.executeSummaryTypes as ReportExecutiveSummary['executeSummaryTypes'])
					: []
			};
		*/
		default:
			return {
				...request,
				reportType: request.reportType
			};
	}
};

export const getFromToPeriod = (
	end: moment.Moment | undefined = moment()
): Required<ReportFormValues>['fromToPeriod'] => [
	moment(end).subtract(1, 'y'),
	end
];

const converter = {
	scheduleFrequency: {
		to: ({ scheduleFrequency }: ReportDetails) => {
			return scheduleFrequency
				? {
						...scheduleFrequency,
						frequency: isString(scheduleFrequency.frequency)
							? moment(scheduleFrequency.frequency)
							: scheduleFrequency.frequency,
						time: moment(
							scheduleFrequency.time,
							APP_CONFIG.TIME_FORMAT
						)
				  }
				: undefined;
		},
		from: ({ scheduleFrequency }: ReportFormValues) => {
			return scheduleFrequency
				? {
						...scheduleFrequency,
						frequency: moment.isMoment(scheduleFrequency.frequency)
							? scheduleFrequency.frequency.format(
									APP_CONFIG.DATE_FORMAT
							  )
							: Number(scheduleFrequency.frequency),
						time: scheduleFrequency.time.format(
							APP_CONFIG.TIME_FORMAT
						)
				  }
				: undefined;
		}
	},
	fromToPeriod: {
		to: ({
			fromToPeriod
		}: ReportDetails): ReportFormValues['fromToPeriod'] => {
			return fromToPeriod
				? [moment(fromToPeriod.from), moment(fromToPeriod.to)]
				: undefined;
		},
		from: ({
			fromToPeriod
		}: ReportFormValues): ReportDetails['fromToPeriod'] => {
			return fromToPeriod
				? {
						from: fromToPeriod[0].format(APP_CONFIG.DATE_FORMAT),
						to: fromToPeriod[1].format(APP_CONFIG.DATE_FORMAT)
				  }
				: undefined;
		}
	},
	groupId: {
		to: ({ group }: ReportDetails): ReportFormValues['groupId'] => {
			return group
				? {
						key: group.id,
						value: group.id,
						label: group.name
				  }
				: undefined;
		},
		from: ({ groupId }: ReportFormValues) => {
			return groupId?.key;
		}
	},
	ownershipGroupId: {
		to: ({
			ownershipGroup
		}: ReportDetails): ReportFormValues['ownershipGroupId'] => {
			return ownershipGroup
				? {
						key: ownershipGroup.id,
						value: ownershipGroup.id,
						label: ownershipGroup.name
				  }
				: undefined;
		},
		from: ({ ownershipGroupId }: ReportFormValues) => {
			return ownershipGroupId?.key;
		}
	}
};
