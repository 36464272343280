import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Row, Col, Fx, Spin } from 'Components';
import { getIsPending } from 'Stores/util';
import { useStores } from 'Hooks/useStore';

import { TotalIndexComposite, Widget } from 'Pages/Dashboard/components';
import {
	getAbsDiffValue,
	getTrendDirection
} from 'Pages/Dashboard/Dashboard.helpers';
import { useDashboardContext } from 'Pages/Dashboard/DashboardContext';
import { DashboardTab } from 'Pages/Dashboard/Dashboard.types';
import DropdownWidget from './DropdownWidget';

import { DashboardTabKey, WidgetData } from 'Services/Api/Dashboard/Types';
import styles from '../Overview.module.scss';
import { usePermission } from 'Components/Permission/Permission.hooks';
import { DASHBOARD_TAB } from 'Pages/Dashboard/Dashboard.constants';
import { PermissionCode } from 'Services/Api/Users/Types';

const TAB_SPAN = 24;

function useWidgets() {
	const { dashboardStore } = useStores();
	const { t } = useTranslation();
	const { getHasPermission } = usePermission();
	const {
		overview: { scores }
	} = dashboardStore;

	const WIDGET_DATA = [
		{
			title: t('dashboard.phishing'),
			data: scores?.phishingScore,
			tab: DASHBOARD_TAB[DashboardTabKey.Phishing],
			//tooltip: t('dashboard.tabs.overview.phishing.tooltip'),
			tooltip: 'Percentage of users passing simulated phishing attempts. Includes a one-month lookback to track progress.',
			permission: {
				code: PermissionCode.ViewDashboardPhishing
			}
		},
		{
			title: t('dashboard.eLearning'),
			data: scores?.elearningScore,
			tab: DASHBOARD_TAB[DashboardTabKey.Elearning],
			//tooltip: t('dashboard.tabs.overview.eLearning.tooltip'),
			tooltip: 'Completion percentage of assigned learning paths in your organisation. Includes a one-month lookback to track progress.',
			permission: {
				code: PermissionCode.ViewDashboardElearning
			}
		},

		{
			title: t('dashboard.spotCheck'),
			data: scores?.spotCheckScore,
			tab: DASHBOARD_TAB[DashboardTabKey.SpotCheck],
			//tooltip: t('dashboard.tabs.overview.spotCheck.tooltip'),
			tooltip: 'Scoring of responses to Spot Check Awareness Audits. Includes a one-month lookback to track progress.',
			permission: {
				code: PermissionCode.ViewDashboardSpotcheck
			}
		}
	];

	const activeWidgets = WIDGET_DATA.filter(
		(widget) => getHasPermission(widget.permission).permitted
	);

	return {
		activeWidgets,
		tabSpan: TAB_SPAN / activeWidgets.length
	};
}

function Widgets() {
	const { t } = useTranslation();
	const { dashboardStore } = useStores();
	const { setActiveTab } = useDashboardContext();
	const { filters } = useDashboardContext();
	const { getHasPermission } = usePermission();
	const {
		overview: { scores },
		status
	} = dashboardStore;

	const { activeWidgets, tabSpan } = useWidgets();
	useEffect(() => {
		dashboardStore.getOverviewScores(filters);
		//eslint-disable-next-line
	}, [filters]);

	const getWidgetData = (
		title: string,
		tab: DashboardTab,
		tooltip: string,
		data?: WidgetData | null
	) => {
		if (!data) {
			return null;
		}

		const { current, hystorical } = data;
		const tabHasPermission =
			!tab.permission || getHasPermission(tab.permission).permitted;
		return (
			<Widget
				value={current}
				title={title}
				diff={getAbsDiffValue(current, hystorical)}
				trendDirection={getTrendDirection(current, hystorical)}
				onClick={
					tabHasPermission ? () => setActiveTab?.(tab.key) : undefined
				}
				tooltip={tooltip}
				trendIndicatorVisible
			/>
		);
	};

	if (getIsPending(status.getOverviewScores)) {
		return (
			<div className={styles.spinWrapper}>
				<Spin />
			</div>
		);
	}

	return (
		<Row gutter={24}>
			<Col span={16}>
				<Row gutter={24} fullHeight>
					{activeWidgets.map(({ title, data, tab, tooltip }) => (
						<Col span={tabSpan} key={tab.key}>
							<Fx fullHeight>
								{getWidgetData(title, tab, tooltip, data)}
							</Fx>
						</Col>
					))}
				</Row>
			</Col>
			<Col span={8}>
				<Fx fullHeight>
					<TotalIndexComposite
						riskScore={scores?.totalIndexComposite.current}
						tooltip={t(
							'dashboard.tabs.overview.totalIndexComposite.tooltip'
						)}
						dropdown={<DropdownWidget />}
						numberOfUsers={scores?.numberOfUsers}
						properties={scores?.properties}
						statisticsVisible
					/>
				</Fx>
			</Col>
		</Row>
	);
}

export default observer(Widgets);
