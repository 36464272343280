import { Row, Col, Fx, Stack } from 'Components';
import CourseActivity from './CourseActivity';
import GroupSummary from './GroupSummary/GroupSummary';
import HistoricalCompletedCourses from './HistoricalCompletedCourses';
import LearningPathSummary from './LearningPathSummary';
import TotalElearningOverview from './TotalElearningOverview';
import TotalElearningScore from './TotalElearningScore';

function Elearning() {
	/*
	return (
		<Stack>
			<Row gutter={24}>
				<Col span={9}>
					<Fx fullHeight>
						<TotalElearningScore />
					</Fx>
				</Col>
				<Col span={15}>
					<Fx fullHeight>
						<LearningPathSummary />
					</Fx>
				</Col>
			</Row>
			<Row gutter={24}>
				<Col span={12}>
					<Fx fullHeight>
						<TotalElearningOverview />
					</Fx>
				</Col>
				<Col span={12}>
					<Fx fullHeight>
						<CourseActivity />
					</Fx>
				</Col>
			</Row>
			<HistoricalCompletedCourses />
			<GroupSummary />
		</Stack>
	);
	*/
	return (
		<Stack>
			<Row gutter={24}>
				<Col span={9}>
					<Fx fullHeight>
						<TotalElearningScore />
					</Fx>
				</Col>
				<Col span={15}>
					<Fx fullHeight>
						<LearningPathSummary />
					</Fx>
				</Col>
			</Row>
			<Row gutter={24}>
				<Col span={12}>
					<Fx fullHeight>
						<TotalElearningOverview />
					</Fx>
				</Col>
				<Col span={12}>
					<Fx fullHeight>
						<CourseActivity />
					</Fx>
				</Col>
			</Row>
			<GroupSummary />
		</Stack>
	);
}

export default Elearning;
