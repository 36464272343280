import { Progress as AntProgress } from 'antd';
import cn from 'classnames';
import { MoodIndicator, StatisticNULL } from '../..';
import styles from './DashboardProgress.module.scss';
import { inPercent } from 'Helpers/numeric';
import { getProgressStatus } from 'Pages/Dashboard/Dashboard.helpers';

const SIZE = {
	large: 170,
	default: 110
};

type HalfCircleProgressProps = {
	percent?: number | null;
	large?: boolean;
};
const DashboardProgressNULL: React.FC<HalfCircleProgressProps> = (props) => {
	const status = getProgressStatus(props.percent);
	const size = props.large ? SIZE.large : SIZE.default;
	const minHeight = `${size}px`;

	return (
		<AntProgress
			gapDegree={145}
			width={size}
			strokeLinecap="square"
			strokeWidth={8}
			type="dashboard"
			className={cn(styles.root, styles[status || ''], {
				[styles.large]: props.large
			})}
			style={{ minHeight }}
			percent={props.percent || undefined}
			format={() => {
				return (
					<StatisticNULL
						vertical
						type={status}
						value={inPercent(props.percent)}
						size={props.large ? 'lg' : 'md'}
						className={styles.label}
					>
						{props.children}
					</StatisticNULL>
				);
			}}
		/>
	);
};

export default DashboardProgressNULL;
