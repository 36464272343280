import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Menu } from 'antd';
import { useHandleDownloadError } from 'Hooks';
import { Button, Icon, Dropdown } from 'Components';
import { qsStringify, toPath } from 'Helpers/navigation';
import { useStores } from 'Hooks/useStore';
import { getAppRoutes } from 'Pages/App/App.constants';
import { useDashboardContext } from 'Pages/Dashboard/DashboardContext';
import { GetUsersRequest } from 'Services/Api/Users/Types';
import styles from './DropdownWidget.module.scss';
import { MenuItem, AxiosErrorResponse } from 'app-types';

enum MenuKey {
	//RiskScore = 'RiskScore',
	Download = 'download',
	ShowUsers = 'ShowUsers',
	ShowProperties = 'ShowProperties'
}

type DopdownMenuItem = MenuItem<MenuKey>;

const MENU_ITEMS: DopdownMenuItem[] = [
	/*
	{
		key: MenuKey.RiskScore,
		title: 'dashboard.tabs.overview.totalIndexComposite.more.riskScore',
		icon: <Icon type="download" className={styles.icon} />
	},
	*/
	{
		key: MenuKey.ShowUsers,
		title: 'dashboard.tabs.overview.totalIndexComposite.more.showUsers',
		icon: <Icon type="users" className={styles.icon} />
	},
	{
		key: MenuKey.ShowProperties,
		title:
			'dashboard.tabs.overview.totalIndexComposite.more.showProperties',
		icon: <Icon type="property" className={styles.icon} />
	}
];

function DropdownWidget() {
	const { t } = useTranslation();
	const history = useHistory();
	const { downloadReportsStore } = useStores();
	const { filters, hasProperties } = useDashboardContext();
	const handleDownloadError = useHandleDownloadError();
	/*
	const downloadRiskScore = async () => {
		try {
			await downloadReportsStore.generateAndDownloadRiskScoreCard(
				filters
			);
		} catch (e) {
			const axiosError = e as AxiosErrorResponse;
			handleDownloadError(axiosError);
		}
	};
	*/
	const onMenuClick = (info: { key: React.Key }) => {
		const menuKey = info.key as DopdownMenuItem['key'];
		const appRoutes = getAppRoutes();
		switch (menuKey) {
			case MenuKey.ShowUsers: {
				const queryParams: GetUsersRequest['filters'] = {
					groupIds: filters?.id ? [String(filters.id)] : []
				};
				const to = toPath({
					path: appRoutes.USERS,
					query: qsStringify(queryParams)
				});
				history.push(to);
				break;
			}
			case MenuKey.ShowProperties:
				const to = toPath({
					path: appRoutes.GROUPS,
					params: {
						groupId: filters?.id
					}
				});
				history.push(to);
				break;
			/*	
			case MenuKey.RiskScore:
				downloadRiskScore();
				break;
			*/
		}
	};

	const menuItems = MENU_ITEMS.filter(
		({ key }) => key !== MenuKey.ShowProperties || hasProperties
	);

	return (
		<Dropdown
			overlay={
				<Menu onClick={onMenuClick}>
					{menuItems.map((menuItem) => (
						<Menu.Item key={menuItem.key}>
							{menuItem.icon}
							{t(menuItem.title)}
						</Menu.Item>
					))}
				</Menu>
			}
			trigger={['click']}
			arrow={false}
			className={styles.dropdown}
		>
			<Button
				shape="round"
				ghost
				type="primary"
				size="small"
				thinVertical
			>
				<Icon type="more" />
			</Button>
		</Dropdown>
	);
}

export default observer(DropdownWidget);
