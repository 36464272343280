import { Card, Fx, OptionalValue } from 'Components';
import { FC } from 'react';
import styles from './Widget.module.scss';
import { MoodIndicator, TrendIndicator, LineProgress } from '..';
import { inPercent } from 'Helpers/numeric';
import { TrendIndicatorDirection } from 'Pages/Dashboard/Dashboard.types';
import { getProgressStatus } from 'Pages/Dashboard/Dashboard.helpers';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
import InfoTooltip from '../InfoTooltip/InfoTooltip';

interface WidgetProps {
	title: string;
	tooltip?: string;
	value?: number | null;
	trendDirection?: TrendIndicatorDirection | null;
	diff?: number | null;
	onClick?: () => void;
	trendIndicatorVisible?: boolean;
}

const Widget: FC<WidgetProps> = ({
	title,
	tooltip,
	value,
	trendDirection,
	diff,
	children,
	onClick,
	trendIndicatorVisible
}) => {
	const { t } = useTranslation();
	const status = getProgressStatus(value);

	const getExtra = () =>
		/*
		isNil(status) ? null : (
			<Fx align="middle">
				<InfoTooltip title={tooltip} enabled={!!tooltip}>
					<MoodIndicator status={status} />
				</InfoTooltip>
			</Fx>
		);
		*/
		isNil(status) ? null : (
			<Fx align="middle">
				<InfoTooltip title={tooltip} enabled={!!tooltip}>
					
				</InfoTooltip>
			</Fx>
		);

	const renderFooter = () => {
		return (
			<div className={styles.footer}>
				{trendIndicatorVisible && (
					<div className={styles.trendContent}>
						<TrendIndicator direction={trendDirection}>
							<OptionalValue>{inPercent(diff)}</OptionalValue>
							&nbsp;
							{t('dashboard.last30Days')}
						</TrendIndicator>
					</div>
				)}
				<LineProgress percent={value} status={status} />
			</div>
		);
	};

	return (
		<Card
			title={title}
			extra={getExtra()}
			className={styles.root}
			headStyle={{ border: 'none' }}
			footer={renderFooter()}
			onClick={onClick}
			hoverable={!!onClick}
		>
			<Fx fullHeight justify="center" column>
				{children}
				<div className={cn(styles.progressValue, styles[status || ''])}>
					<OptionalValue>{inPercent(value)}</OptionalValue>
				</div>
			</Fx>
		</Card>
	);
};

export default Widget;
