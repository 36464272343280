import moment from 'moment';
import * as yup from 'yup';
import { VALIDATION } from 'Helpers/validations';
import {
	Reports,
	ReportTimePeriod,
	ReportRecurringFrequency,
	ReportType
} from 'Services/Api/Reports/Types';
import { REPORT_FIELD_LABEL } from './EditReport.constants';
import { CheckboxValueType, CheckboxOptionType } from 'antd/lib/checkbox/Group';
import { LabelValueItem, Replace } from 'app-types';

export type ReportFormValues = Replace<Reports, ReportFormValuesOverriden> & {
	data: ReportFormData;
	id?: number;
};

type ReportFormValuesOverriden = {
	scheduleFrequency?: Replace<
		Required<Reports>['scheduleFrequency'],
		{
			time: moment.Moment;
			frequency?: number | moment.Moment;
		}
	>;
	emailsList?: string[];
	fromToPeriod?: [moment.Moment, moment.Moment];
	executeSummaryTypes?: CheckboxValueType[];
	groupId?: LabelValueItem<number>;
	ownershipGroupId?: LabelValueItem<number>;
};
export type ReportFormData = {
	tabs?: CheckboxOptionType[];
};

export const schema = yup
	.object()
	.shape<Partial<Record<keyof ReportFormValues, yup.AnySchema>>>({
		name: VALIDATION.reportName.label(REPORT_FIELD_LABEL.name).required(),
		fileType: yup.string().label(REPORT_FIELD_LABEL.fileType).required(),
		definedPeriod: yup
			.string()
			.nullable()
			.when(['timePeriodType', 'fromToPeriod', 'reportType'], {
				is: (
					timePeriod: ReportFormValues['timePeriodType'],
					fromToPeriod: ReportFormValues['fromToPeriod'],
					reportType: ReportFormValues['reportType']
				) =>
					timePeriod === ReportTimePeriod.Historical &&
					!fromToPeriod,/* &&
					reportType !== ReportType.RiskScore,*/
				then: (s) => s.required()
			}),

		displayResultsBy: yup
			.string()
			.nullable()
			.label(REPORT_FIELD_LABEL.displayResultsBy)
			.when(['reportType', 'timePeriodType'], {
				is: (
					reportType: ReportFormValues['reportType'],
					timePeriodType: ReportFormValues['timePeriodType']
				) =>
					timePeriodType === ReportTimePeriod.Historical,/* &&
					reportType !== ReportType.RiskScore,*/
				then: (s) => s.required()
			}),
		emailsList: yup
			.array()
			.label(REPORT_FIELD_LABEL.emailsList)
			.of(VALIDATION.email.label(REPORT_FIELD_LABEL.emailsList))
			.when(['isRecurring', 'emailMeReport'], {
				is: (
					isRecurring: ReportFormValues['isRecurring'],
					emailMe: ReportFormValues['emailMeReport']
				) => isRecurring && !emailMe,
				then: (s) => s.min(1).required()
			}),
		scheduleFrequency: yup.object().when('isRecurring', {
			is: (value: ReportFormValues['isRecurring']) => value,
			then: yup
				.object({
					type: yup
						.number()
						.label(REPORT_FIELD_LABEL.scheduleFrequency)
						.required(),
					frequency: yup
						.mixed()
						.when('type', (value: ReportRecurringFrequency) => {
							switch (value) {
								case ReportRecurringFrequency.Annually:
									return yup.date().required();
								case ReportRecurringFrequency.Weekly:
									return yup
										.number()
										.min(1)
										.max(7)
										.required();
								case ReportRecurringFrequency.Monthly:
								case ReportRecurringFrequency.Quarterly:
									return yup
										.number()
										.min(1)
										.max(31)
										.required();
								default:
									return yup.number().nullable();
							}
						}),
					time: yup.date().required()
				})
				.required()
		})/*,
		executeSummaryTypes: yup
			.array()
			.nullable()
			.when('reportType', {
				is: (value: ReportFormValues['reportType']) =>
					value === ReportType.ExecutiveSummary,
				then: (s) => s.min(1)
			})
		*/
	});
